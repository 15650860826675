<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col v-if="true" cols="1" class="px-0" style="min-width:38px;">
                    <a class="edit-toggle pa-2" @click="toggleEdit()">
                        <v-icon>
                            {{ edit ? 'mdi-message-bulleted-off' : 'mdi-message-bulleted' }}
                        </v-icon>
                    </a>
                </v-col>

                <v-col v-else cols="1">
                    <v-btn text
                        :to="`/contacts/${data.id}`">
                        <v-icon>
                            mdi-link
                        </v-icon>
                    </v-btn>
                </v-col>

                <v-col>
                    <div v-if="edit">
                        <InputMask type="contact"
                            v-on:edit-item-saved="itemSaved(arguments)"
                            :confirmable="false"
                            :inputData="data"/>
                    </div>
                <v-row v-else>
                    <v-container>
                        <v-row v-if="data.surname || data.name">
                            {{ title }}
                            {{ data.name }}
                            {{ data.surname }}
                            {{ data.role ? '('+data.role+')' : '' }}
                        </v-row>

                        <v-row v-if="data.company">
                            {{ data.company }}
                        </v-row>

                        <v-row v-if="data.street || data.zipCode || data.city">
                            {{ data.street ? data.street+',' : '' }}
                            {{ data.zipCode }}
                            {{ data.city }}
                        </v-row>

                        <v-row v-if="data.country">
                            {{ data.country }}
                        </v-row>

                        <v-row v-if="phones.length > 0">
                            <v-col cols="2" v-for="(item, idx) of phones"
                                :key="idx">
                                <v-btn text
                                    color="info"
                                    :href="`tel:${item}`">
                                    {{ item }}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="emails.length > 0">
                            <v-col cols="3" v-for="(item, idx) of emails"
                                :key="idx">
                                <v-btn text
                                    color="info"
                                    :href="`mailto:${item}`">
                                    {{ item }}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="data.note">
                            <i>
                                <span style="white-space: pre-line">
                                    {{ data.note }}
                                </span>
                            </i>
                        </v-row>
                    </v-container>
                </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
const InputMask = () => import(
    /* webpackChunkName: "input-mask" */ '@/components/InputMaskTabs.vue'
)
const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        editable: {
            default() {
                return true
            },
            type: Boolean,
        },
        dataBody: {
            default() {
                return {
                    gender: null,
                    name: null,
                    surname: null,
                    company: null,
                    role: null,
                    zipCode: null,
                    street: null,
                    city: null,
                    country: null,
                    numbers: null,
                    mails: null,
                    note: null,
                    enabled: false,
                    timezone: 'Europe/Berlin',
                    updatedAt: moment(),
                }
            },
            type: Object,
        },
    },

    components: {
        InputMask,
    },

    data() {
        return {
            edit: false,
            displayContent: this.content,
        }
    },

    computed: {
        title() {
            let pre = ''
            if (this.data.gender === 'male') {
                pre = 'Mr.'
            } else if (this.data.gender === 'female') {
                pre = 'Ms.'
            }
            return pre
        },

        emails() {
            return this.data.mails ? this.data.mails.split(',') : []
        },

        phones() {
            return this.data.numbers ? this.data.numbers.split(',') : []
        },
    },

    methods: {
        toggleEdit() {
            this.edit = !this.edit
        },

        itemSaved(item) {
            this.displayContent = item[0].data
            this.data = item[0].data
            this.edit = false
        },
    },

    created() {
        this.data = this.dataBody
    },
}
</script>
