import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VRow,[(true)?_c(VCol,{staticClass:"px-0",staticStyle:{"min-width":"38px"},attrs:{"cols":"1"}},[_c('a',{staticClass:"edit-toggle pa-2",on:{"click":function($event){return _vm.toggleEdit()}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.edit ? 'mdi-message-bulleted-off' : 'mdi-message-bulleted')+" ")])],1)]):_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"text":"","to":("/contacts/" + (_vm.data.id))}},[_c(VIcon,[_vm._v(" mdi-link ")])],1)],1),_c(VCol,[(_vm.edit)?_c('div',[_c('InputMask',{attrs:{"type":"contact","confirmable":false,"inputData":_vm.data},on:{"edit-item-saved":function($event){return _vm.itemSaved(arguments)}}})],1):_c(VRow,[_c(VContainer,[(_vm.data.surname || _vm.data.name)?_c(VRow,[_vm._v(" "+_vm._s(_vm.title)+" "+_vm._s(_vm.data.name)+" "+_vm._s(_vm.data.surname)+" "+_vm._s(_vm.data.role ? '('+_vm.data.role+')' : '')+" ")]):_vm._e(),(_vm.data.company)?_c(VRow,[_vm._v(" "+_vm._s(_vm.data.company)+" ")]):_vm._e(),(_vm.data.street || _vm.data.zipCode || _vm.data.city)?_c(VRow,[_vm._v(" "+_vm._s(_vm.data.street ? _vm.data.street+',' : '')+" "+_vm._s(_vm.data.zipCode)+" "+_vm._s(_vm.data.city)+" ")]):_vm._e(),(_vm.data.country)?_c(VRow,[_vm._v(" "+_vm._s(_vm.data.country)+" ")]):_vm._e(),(_vm.phones.length > 0)?_c(VRow,_vm._l((_vm.phones),function(item,idx){return _c(VCol,{key:idx,attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"text":"","color":"info","href":("tel:" + item)}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),1):_vm._e(),(_vm.emails.length > 0)?_c(VRow,_vm._l((_vm.emails),function(item,idx){return _c(VCol,{key:idx,attrs:{"cols":"3"}},[_c(VBtn,{attrs:{"text":"","color":"info","href":("mailto:" + item)}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),1):_vm._e(),(_vm.data.note)?_c(VRow,[_c('i',[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.data.note)+" ")])])]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }